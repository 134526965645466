import { PLANYO } from "../config";

export const CANCELLED_STATUSES = PLANYO ? [
    8,
    9,
    11,
    15,
    16,
    17,
    19,
    23,
    24,
    25,
    27,
    49,
    51,
    75,
    144,
    145,
    147,
    177,
    179,
    264,
    265,
    267,
    272,
    273,
    275,
    280,
    281,
    283,
    305,
    307,
    400,
    401,
    403,
    433,
    435,
    512,
    521,
    523,
    777,
    779,
    2056
] : [17];

export const PENDING_STATUSES = PLANYO ? [
    0,
    1,
    3,
    128,
    129,
    131,
    256,
    257,
    259,
    384,
    385,
    387
] : [0, 1];

export const CONFIRMED_STATUSES = PLANYO ? [
    5,
    7,
    37,
    39,
    133,
    135,
    165,
    167,
    261,
    263,
    293,
    295,
    389,
    391,
    421,
    423,
] : [5];

// this has been added to cater to the specific case which are pending due to payment reasons
export const PAYMENT_STATUSES = PLANYO ? [
    0, //booking not completed
    128, //reservation conflict
    256, //infinite qty 256 + 0 , in case of confirmed it becomes 256 + 5
    384 // 256 + 128 + 0, if resource is marked unavailable by admin
] : [0]